<template>
  <div class="document-item" v-if="document">
    <router-link :to="route" class="document-item__icon">
      <IconComponent name="document-text" />
    </router-link>
    <router-link :to="route" class="document-item__content">
      <span class="document-item__title" v-if="document.title">{{ document.title }}</span>
    </router-link>
    <a
      v-if="document.document"
      :href="document.document.path"
      :download="document.document.origin_name"
      class="btn btn--main btn--md"
    >
      <IconComponent name="receive-square" />
      <span>
        Скачать
        <template v-if="document.document.type && document.document.size">
          ({{ document.document.type }}, {{ document.document.size }})
        </template>
      </span>
    </a>
  </div>
</template>
<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "DocumentItemComponent",
  props: {
    document: Object,
    activity: Boolean,
    link: Boolean,
  },
  computed: {
    route() {
      if (!this.link) {
        return {
          name: "document",
          params: { id: this.document.id },
        };
      } else {
        return {
          name: "",
        };
      }
    },
    shouldShowDate() {
      const hideDateRoutes = ["/countering-extremism", "/anti-corruption", "/theater-documents"];
      return !hideDateRoutes.includes(this.$route.path);
    },
  },
  components: { IconComponent },
};
</script>

<style lang="stylus">
.document-item {
  background: var(--white);
  border: 1px solid var(--gray-dark);
  border-radius: 10px;
  padding 20px
  display grid
  grid-template-columns 60px 1fr auto
  grid-gap 20px
  width 100%
  align-items center
  +below(768px) {
    grid-template-columns 1fr
    grid-gap 15px
  }

  &:hover {
    border-color var(--main)
  }

  &__icon {
    background: var(--gray);
    border-radius: 100px;
    width 60px
    height: 60px;
    display flex
    align-items center
    justify-content center

    .icon {
      width 30px
      height: 30px;

      svg path {
        fill var(--main)
      }
    }
  }

  &__content {
    display grid
    grid-gap 10px
  }

  &__header {
    display flex
    align-items center
    gap 24px
    font-size: 0.75em;
    line-height: 14px;
    color: var(--dark-light);

    & > *:not(:last-child) {
      position relative

      &:after {
        content "•"
        font-weight 700
        absolute right -14px top bottom
        margin auto
        font-size: 1em;
        line-height: 14px;
        color: var(--dark-light);
      }
    }
  }

  &__number,
  &__category {
    font-weight: 700;
  }

  &__date {
    font-weight: 500;
  }

  &__title {
    font-weight: 500;
    font-size: 1.125em;
    line-height: 28px;
    color: var(--dark);
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    +below(768px) {
      font-size: 1em;
      line-height: 26px;
    }
  }

  &__description {
    font-weight: 400;
    font-size: 1em;
    line-height: 28px;
    color: var(--dark);
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    +below(768px) {
      font-size: 0.875em;
      line-height: 26px;
    }
  }
}
</style>
